export const CountOfCategories = 3;

export const USER_MENU_ITEMS = {
  MY_TICKETS: 'menu-item-myTickets',
  SETTINGS: 'menu-item-settings',
  COMMUNICATION_PREFERENCES: 'menu-item-communication-preferences',
  INTEGRATIONS: 'menu-item-integrations',
  PAYMENT_METHODS: 'menu-item-payment-methods',
  FAVORITE_EVENTS: 'menu-item-favorite-events',
  INTERESTS: 'menu-item-interests',
  MY_FRIENDS: 'menu-item-my-friends',
  MY_ORGANIZATIONS: 'menu-item-my-organizations',
};
