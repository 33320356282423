import React, { forwardRef } from 'react';
import { Fab as MuiFab, FabProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps extends FabProps {
  className?: string;
  elevation?: 0 | 1 | 2;
  onClick: any;
}

const useStyles = makeStyles()({
  elevation0: {
    boxShadow: 'none',
  },
  elevation1: {
    boxShadow: '0px 2px 4px rgba(60, 85, 165, 0.16)',
  },
  elevation2: {
    boxShadow: '0px 4px 8px #233558',
  },
});

const Fab = forwardRef<React.FC, IProps>(({ elevation, className, children, ...props }, ref: any) => {
  const { classes, cx } = useStyles();
  return (
    <MuiFab className={cx(classes[`elevation${elevation}` as keyof typeof classes], className)} ref={ref} {...props}>
      {children}
    </MuiFab>
  );
});

(Fab as any).muiName = 'Fab';

export default Fab;
