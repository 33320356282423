import React, { forwardRef, memo, ReactNode } from 'react';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { capitalize } from '@mui/material/utils';
import palette from 'theme/palette';

const colors: any = {
  lightOrange: palette.custom.orange.light,
  white: palette.custom.white,
  gray: palette.custom.gray.main,
  grayLight: palette.custom.gray.light,
  purple: palette.custom.purple.main,
  extraLightPurple: palette.custom.purple.extraLight,
  green: palette.custom.green.main,
  primary: palette.primary.main,
  secondary: palette.secondary.main,
  darkGray: palette.custom.gray.dark,
  default: palette.background.default,
  lightGray: palette.custom.gray.medium,
  lightGreen: palette.custom.green.light,
  lightPrimary: palette.primary.light,
  extraLightPrimary: palette.primary.extraLight,
  lightSecondary: palette.secondary.light,
  extraLightSecondary: palette.secondary.extraLight,
  textPrimary: palette.text.primary,
};

interface IProps {
  ref?: any;
  label?: ReactNode;
  component?: any;
  color?: string;
  textColor?: string;
  variant?: string;
  size?: string;
  hasHover?: boolean;
  onClick?: () => void;
}

interface CustomStyleProps {
  color: string;
  variant: string;
  hasHover: boolean;
  size: string;
  textColor: string;
}

const useStyles = makeStyles<CustomStyleProps, 'sizeSmall' | 'sizeMedium' | 'sizeLarge' | 'label'>()(
  (theme, params, classes) => ({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: params.hasHover ? 'pointer' : 'default',
      borderColor:
        params.variant === 'outlined'
          ? (params.color === 'white' && theme.palette.custom.border.gray) || colors[params.color]
          : 'unset',
      backgroundColor: params.variant !== 'outlined' ? colors[params.color] : 'unset',
      '&:hover': {
        ...(params.hasHover && {
          borderColor: params.variant === 'outlined' ? alpha(colors[params.color], 0.6) : 'inherit',
          backgroundColor: params.variant !== 'outlined' ? alpha(colors[params.color], 0.6) : 'inherit',
        }),
      },
    },
    label: {
      fontWeight: theme.typography.fontWeightMediumBold,
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
      color: colors[params.textColor],
    },
    sizeSmall: {
      minHeight: 20,
      padding: '2px 8px',
      borderRadius: 3,
      [`.${classes.label}`]: {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
      },
    },
    sizeMedium: {
      minHeight: 32,
      padding: theme.spacing(1, 2),
      borderRadius: 3,
      [`.${classes.label}`]: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
      },
    },
    sizeLarge: {
      minHeight: 48,
      padding: theme.spacing(0, 2),
      borderRadius: 8,
      [`.${classes.label}`]: {
        fontSize: theme.typography.h6.fontSize,
        lineHeight: theme.typography.h6.lineHeight,
      },
    },
    variantOutlined: {
      borderWidth: 1,
      borderStyle: 'solid',
    },
    variantContained: {},
  }),
);

const Label: React.FC<React.PropsWithChildren<IProps>> = forwardRef((props, ref) => {
  const {
    label,
    color = 'gray',
    textColor = 'white',
    variant = 'contained',
    size = 'medium',
    component: Component = 'div',
    hasHover = false,
    onClick,
    ...rest
  } = props;

  const { classes, cx }: any = useStyles({ ...{ color, variant, size, hasHover, textColor }, ...rest });

  return (
    <Component
      ref={ref}
      className={cx(
        classes.root,
        classes[`variant${capitalize(variant)}` as keyof typeof classes],
        classes[`size${capitalize(size)}` as keyof typeof classes],
        {
          [classes[`hover${capitalize(color)}` as keyof typeof classes]]: hasHover,
        },
      )}
      onClick={onClick}
    >
      <span className={classes.label}>{label}</span>
    </Component>
  );
});

export default memo(Label);
